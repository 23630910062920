import React, { Component } from 'react'
import axios from 'axios'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import styled from 'styled-components'
import { withSnackbar } from 'notistack';




// const validate = (values, props) => {
//   let errors = {};
//   if (!values.email) {
//     errors.email = 'Required';
//   } else if (
//     !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
//   ) {
//     errors.email = 'Invalid email address';
//   }
//   return errors;
// }

const validate = (values, props) => {
  let errors = {};
  console.log(values);

  if (!values.fullname) {
    errors.fullname = 'Required';
    props.enqueueSnackbar('We need a name!', {variant: 'error', preventDuplicate: true})
  }
  else if (values.email !="" && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
    props.enqueueSnackbar("Hmm that email address doesn't look right", {variant: 'error', preventDuplicate: true})
  }
  else if (values.email === "") {
    errors.email = 'Invalid email address';
    // props.enqueueSnackbar("Please enter an email", {variant: 'error', preventDuplicate: true})
  }
  return errors;
}


const FormButton = styled.button`
  background-color: ${props => props.bgColour || "rgb(36, 35, 53)"};
  transition: all 0.5s ease-in;
`

const handleSubmit = async (values, setSubmitting, props) => {
  console.log("Values", values);
  const response = await axios.post('https://us-central1-jd-microservices-70972.cloudfunctions.net/api/v1/send', values)
  console.log(response)

  props.enqueueSnackbar("successfully sent email", {variant: 'success'})
  setTimeout(() => {
    setSubmitting(false)
  }, 2000)

}

const renderForm = ({isSubmitting}) => (
    <Form className="form-main" autoComplete="off">
      <div className="form-field-group">
        <Field type="text" name="fullname" placeholder="Full Name*" className="form-field" />
      </div>

      <div className="form-field-group">
        <Field type="text" name="email" placeholder="email address*" className="form-field" />
      </div>

      <FormButton type="submit" disabled={isSubmitting} className="form-button" bgColour={isSubmitting ? "lightslategray" : 'rgb(36, 35, 53)'}>
        {isSubmitting ? "Submitting" : "Send Email"} <div className="submit-arrow">&rarr;</div>
      </FormButton>

    </Form>
  )



const EmailForm = (props) =>  {
    return(
      <div className="form">
        <div className="form-header-block">
          Fill out the form below to send the customer an information email
        </div>
        <div className="form-wrapper">
          <Formik
            validateOnBlur={true}
            validateOnChange={false}
            initialValues={{fullname: "", email: ""}}
            validate={values => validate(values, props)}
            onSubmit={(values, { setSubmitting }) => {handleSubmit(values, setSubmitting, props)}}
            render={isSubmitting => renderForm(isSubmitting)}
            />
        </div>
      </div>
    )
}

export default withSnackbar(EmailForm)
