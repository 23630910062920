import React, { Component } from 'react';
import logo from './logo.svg';
import './styles/App.scss';
import Form from './Form.js'
import LoginForm from './LoginForm.js'


import { FirebaseAuthProvider, FirebaseAuthConsumer } from "@react-firebase/auth";
import * as firebase from "firebase";


import { config } from "./credentials";



import Lottie from 'react-lottie';


import logoAnimation from './assets/logo.json'



class App extends Component {

  renderForm = () => {
    return(
      <FirebaseAuthProvider {...config} firebase={firebase}>
        <FirebaseAuthConsumer>
          {({ isSignedIn }) => {
            if (isSignedIn === true) {
              return <Form />
            } else {
              return (
                <LoginForm firebase={firebase}/>
              )
            }
          }}
        </FirebaseAuthConsumer>

      </FirebaseAuthProvider>
    )
  }

  render() {
    let logoAnimationOptions = {
      loop: true,
      autoplay: true,
      animationData: logoAnimation,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    }

    return (

        <div className="App">
        <header className="header">
          <div className="left-side">
            <div className="logoAnimation">
              <Lottie
                options={logoAnimationOptions}
                width={342}
                height={154}
              />
              <div className="getInTouch">
                Get In Touch
              </div>
            </div>
          </div>
          <div className="right-side">
            <div className="menu">

            </div>
          </div>
        </header>

        {this.renderForm()}

        <div className="bottom">
          <div className="bottom-lines">
            <div className="bottom-line" />
            <div className="bottom-line" />
          </div>
          <div className="bottom-text">
            WILLING TO PICK UP ANYTHING
          </div>
        </div>
        </div>


    );
  }
}

export default App;
