import React from 'react';
import ReactDOM from 'react-dom';
import './styles/App.scss';

import App from './App';
import { SnackbarProvider } from 'notistack';

import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <SnackbarProvider
    maxSnack={3}
    anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
    }}
    >
    <App />
</SnackbarProvider>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
