import React, { Component } from 'react'
import axios from 'axios'
import { Formik, Form, Field, ErrorMessage } from 'formik'
import styled from 'styled-components'
import { withSnackbar } from 'notistack';




const validate = (values, props) => {
  let errors = {};
  console.log(values);

  if (values.email === "") {
    errors.email = 'Invalid email address';
    props.enqueueSnackbar("You forgot your email address!", {variant: 'error', preventDuplicate: true})
    // props.enqueueSnackbar("Please enter an email", {variant: 'error', preventDuplicate: true})
  }
  else if (values.email !="" && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
    errors.email = 'Invalid email address';
    props.enqueueSnackbar("Hmm that email address doesn't look right", {variant: 'error', preventDuplicate: true})
  }
  else if (!values.password) {
    errors.password = 'Required';
    props.enqueueSnackbar('You forgot your password!', {variant: 'error', preventDuplicate: true})
  }
  return errors;
}


const FormButton = styled.button`
  background-color: ${props => props.bgColour || "rgb(36, 35, 53)"};
  transition: all 0.5s ease-in;
`

const handleSubmit = async (values, setSubmitting, props) => {
  const { email, password } = values
  try {
    await props.firebase.auth().signInWithEmailAndPassword(email, password)
    props.enqueueSnackbar("successfully logged in", {variant: 'success'})
    setTimeout(() => {
      setSubmitting(false)
    }, 2000)
  } catch (error) {
    var errorCode = error.code;
    var errorMessage = error.message;
    props.enqueueSnackbar(errorMessage, {variant: 'error'})
    return
  }



}

const renderForm = ({isSubmitting}) => (
    <Form className="form-main" autoComplete="off">
      <div className="form-field-group">
        <Field type="email" name="email" placeholder="email*" className="form-field" />
      </div>

      <div className="form-field-group">
        <Field type="password" name="password" placeholder="password*" className="form-field" />
      </div>

      <FormButton type="submit" disabled={isSubmitting} className="form-button" bgColour={isSubmitting ? "lightslategray" : 'rgb(36, 35, 53)'}>
        {isSubmitting ? "Sit Tight" : "Sign In"} <div className="submit-arrow">&rarr;</div>
      </FormButton>

    </Form>
  )



const EmailForm = (props) =>  {
    return(
      <div className="form">
        <div className="form-header-block">
          Please login with your email and password
        </div>
        <div className="form-wrapper">
          <Formik
            validateOnBlur={false}
            validateOnChange={false}
            initialValues={{password: "", email: ""}}
            validate={values => validate(values, props)}
            onSubmit={(values, { setSubmitting }) => {handleSubmit(values, setSubmitting, props)}}
            render={isSubmitting => renderForm(isSubmitting)}
            />
        </div>
      </div>
    )
}

export default withSnackbar(EmailForm)
